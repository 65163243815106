import { enableProdMode, StaticProvider } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from './environments/environment';
import { APP_OPTIONS, AppOptions, BRAND_OPTIONS } from '@unleashed/common/config';
import { AppModule } from '~/app.module';

if (environment.production) {
  enableProdMode();
}

const providers: StaticProvider[] = [
  {
    provide: APP_OPTIONS,
    useValue: {
      uaAdminUrl: environment.uaAdminUrl,
      parkApiUrl: environment.parkApiUrl,
      productApiUrl: environment.productApiUrl,
      invoiceApiUrl: environment.invoiceApiUrl,
      accountApiUrl: environment.accountApiUrl,
      mediaApiUrl: environment.mediaApiUrl,
      bookingApiUrl: environment.bookingApiUrl,
      unleashedApiUrl: environment.unleashedApiUrl,
      salesForceUrl: environment.salesForceUrl,
      salesForceUrlJs: environment.salesForceUrlJs,
      salesForceBaseLiveAgentUrl: environment.salesForceBaseLiveAgentUrl,
      salesForceBaseLiveAgentContentUrl: environment.salesForceBaseLiveAgentContentUrl,
      salesForceLiveAgentSetupFlowUrl: environment.salesForceLiveAgentSetupFlowUrl,
      salesForceKey: environment.salesForceKey,
      cookieName: environment.cookieName,
      bookingCookieName: environment.bookingCookieName,
      enableGtm: environment.enableGtm,
      auth: {
        issuer: environment.auth.issuer,
        clientId: environment.auth.clientId,
        clientSecret: environment.auth.clientSecret,
        scopes: environment.auth.scopes,
        cookieOptions: {
          namespace: environment.auth.cookies.namespace,
          domain: environment.auth.cookies.domain,
          duration: environment.auth.cookies.duration,
          secure: environment.auth.cookies.secure
        }
      }
    } as AppOptions
  },
  {
    provide: BRAND_OPTIONS,
    useValue: {
      brandId: 3,
      brandSlug: 'the-little-gym',
      name: 'The Little Gym',
      useParkPhoneNumber: false,
      productLevelUpsellText: '{0} will miss out on some of our AWESOME benefits.',
      attendeesText: 'Attend',
      showInvoiceTheme: false,
      showInvoiceHangout: false,
      showSpecialEventText: false,
    }
  }
];

platformBrowserDynamic(providers).bootstrapModule(AppModule)
  .catch(err => console.error(err));
